<template>
  <BaseCard class="tw-my-4">
    <div class="tw-p-6">
      <div v-if="isLoading || isStudioPlusCampaignsLoading || isStudioPlusCampaignsFetching">
        <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true"></div>
      </div>

      <div
        v-else-if="isError && isStudioPlusCampaignsError"
        class="tw-text-red-800"
        v-html="$t('errors.wrong_data_from_app')"
      />

      <div v-else-if="!Array.isArray(subscriptions) || !subscriptions.length">
        {{ $t('subscriptions.empty') }}
      </div>

      <div v-else>
        <ul
          v-if="subscriptionsByStatus.active.length > 0"
          class="tw-flex tw-flex-col tw-gap-4 tw-mb-4 tw-border-gray-300"
        >
          <div class="tw-font-bold tw-text-xl">{{ $t('active_subscriptions_header') }}</div>
          <li
            v-for="(activeSubscription, index) in subscriptionsByStatus.active"
            :key="index"
            class="tw-mb-2 tw-p-4 tw-bg-gray-100 tw-rounded-lg"
          >
            <SubscriptionsItemPreview
              :accommodationName="activeSubscription.accommodationName"
              :subscription="activeSubscription"
              :accommodationId="activeSubscription.accommodationId"
            />
          </li>
        </ul>
        <ul v-if="subscriptionsByStatus.expired.length > 0" class="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <div class="tw-font-bold tw-text-xl">{{ $t('expired_subscriptions_header') }}</div>
          <li
            v-for="(expiredSubscription, index) in subscriptionsByStatus.expired"
            :key="index"
            class="tw-mb-2 tw-p-4 tw-bg-gray-100 tw-rounded-lg"
          >
            <SubscriptionsItemPreview
              :accommodationName="expiredSubscription.accommodationName"
              :subscription="expiredSubscription"
              :accommodationId="expiredSubscription.accommodationId"
            />
          </li>
        </ul>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import SubscriptionsItemPreview from '@/components/settings/subscriptions/SubscriptionsItemPreview.vue'

import { useSubscriptions, useStudioPlusCampaigns } from '@/components/settings/subscriptions/queries'
import { useAccommodationsByOrgId } from '@/layouts/queries'

import useToastNotifications from '@/components/notifications/useToastNotifications'

const route = useRoute()
const store = useStore()

const selectedOrganisationId = computed(() => store.state?.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const { studioPlusCampaigns, isStudioPlusCampaignsLoading, isStudioPlusCampaignsError, isStudioPlusCampaignsFetching } =
  useStudioPlusCampaigns(selectedOrganisationId)
const { subscriptionsByAccommodations, isLoading, isError } = useSubscriptions(selectedOrganisationId)
const { displayNotification } = useToastNotifications()

const getStatus = subscription => {
  if (subscription.endDate === null) {
    return 'active'
  } else if (new Date() > new Date(subscription.endDate)) {
    return 'expired'
  } else {
    return 'canceled'
  }
}

const subscriptions = computed(() => {
  const rateConnectProductId = import.meta.env.VITE_RATE_CONNECT_PRODUCT_ID

  let allSubscriptions = []

  for (const accommodation in accommodationsData.value) {
    const subscription = {
      accommodation: {
        accommodation_id: accommodationsData.value[accommodation].accommodationId,
        name: accommodationsData.value[accommodation].name,
      },
      subscriptions: [],
    }

    const nonPlusSubscriptionsInAccommodation = subscriptionsByAccommodations.value?.find(
      item => item.accommodation?.accommodation_id === +accommodationsData.value[accommodation].accommodationId
    )?.subscriptions

    if (nonPlusSubscriptionsInAccommodation?.length > 0) {
      const rcSubscriptions = nonPlusSubscriptionsInAccommodation.filter(
        item => item.product?.id === rateConnectProductId
      )
      subscription.subscriptions = [...rcSubscriptions]
    }

    const plusCampaignInAccommodation = studioPlusCampaigns.value?.find(
      item => item.itemId === +accommodationsData.value[accommodation].accommodationId
    )

    if (plusCampaignInAccommodation) {
      const plusSubscription = {
        hasActiveRC: accommodationHasRateConnect(nonPlusSubscriptionsInAccommodation, plusCampaignInAccommodation),
        isStudioPlus: true,
        subscription_id: `studio-plus_${plusCampaignInAccommodation.itemId}`,
        subscription_number: plusCampaignInAccommodation.zuoraSubscriptionName,
        nextPaymentDate: plusCampaignInAccommodation.nextPaymentDate,
        subscriptionStartDate: plusCampaignInAccommodation.subscriptionStartDate,
        status: getStatus(plusCampaignInAccommodation),
        subscriptionType: plusCampaignInAccommodation.term,
        endDate: plusCampaignInAccommodation.endDate,
        product: {
          details: [{ name: plusCampaignInAccommodation.type === 'PLUS' ? 'Business Studio+' : 'PRO Package' }],
        },
        pricing_plan: null,
        createdAt: plusCampaignInAccommodation.createdAt,
        partnerId: plusCampaignInAccommodation.partnerId,
      }
      subscription.subscriptions = [...subscription.subscriptions, plusSubscription]
    }
    allSubscriptions.push(subscription)
  }

  return allSubscriptions.filter(item => item.subscriptions.length > 0)
})

const accommodationHasRateConnect = (otherSubscriptions, plusSubscription) => {
  if (otherSubscriptions.length > 0 && plusSubscription) {
    for (const subscription in otherSubscriptions) {
      if (
        otherSubscriptions[subscription].product?.id === import.meta.env.VITE_RATE_CONNECT_PRODUCT_ID &&
        otherSubscriptions[subscription].status === 'active'
      )
        return true
      else {
        return false
      }
    }
  } else {
    return false
  }
}

const subscriptionsByStatus = computed(() => {
  const subscriptionsWithData = subscriptions.value
  const subscriptionsWithStatus = {
    active: [],
    expired: [],
  }

  subscriptionsWithData.forEach(subscription => {
    subscription?.subscriptions.forEach(item => {
      let statusValue
      if (item.status === 'cancelled' || item.status === 'canceled') {
        statusValue = 'active'
      } else {
        statusValue = item.status
      }

      subscriptionsWithStatus[statusValue].push({
        accommodationId: subscription.accommodation.accommodation_id,
        accommodationName: subscription.accommodation.name,
        isStudioPlus: item.isStudioPlus,
        subscription_id: item.subscription_id,
        subscription_number: item.subscription_number,
        nextPaymentDate: item.nextPaymentDate,
        subscriptionStartDate: item.subscriptionStartDate,
        status: item.status,
        endDate: item.isStudioPlus ? item.endDate : item.term_end_date,
        product: item.product,
        pricing_plan: item.isStudioPlus ? item.subscriptionType : item.pricing_plan,
        createdAt: item.createdAt,
        partnerId: item.isStudioPlus ? item.partnerId : null,
        hasActiveRC: item.isStudioPlus ? item.hasActiveRC : null,
      })
    })
  })
  return subscriptionsWithStatus
})

onMounted(() => {
  if (route.query?.reactivate === 'true') {
    displayNotification({
      message: 'business_studio_plus_reacativation_success',
      isTranslationKey: true,
      type: 'success',
    })
  }
})
</script>
