<template>
  <div v-if="isLoading && subscriptionsByAccommodations.length" class="tw-my-6">
    <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
    <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
  </div>
  <div
    v-else
    class="tw-py-8 tw-mb-8 tw-border-b tw-border-gray-300 tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-items-end"
  >
    <!-- For Rate Connect -->
    <div v-if="productName.includes('Rate Connect') || productName.includes('rateConnect')">
      <div class="tw-font-bold tw-text-xl tw-mb-4" :class="isActiveSubscription ? 'tw-text-green-600' : ''">
        {{ $t('campaign_status_active') }}
      </div>
      <p v-html="$t('subscriptions.details.nextPayment.usageBased.date')" />
    </div>
    <!-- For PRO & Plus -->
    <div v-else>
      <!-- Active Subscription -->
      <div v-if="isActiveSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-green-600': isActiveSubscription }">
          {{ $t('subscription_status_active') }}
        </div>
        <p
          v-if="subscriptionTerm === 'annual'"
          v-html="
            $t('subscription_renew_info_text_annual', {
              ReactivationDate: nextPaymentDate,
              SubscriptionPrice: `${Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency,
              }).format(price)}`,
            })
          "
        />
        <p
          v-else
          v-html="
            $t('subscription_renew_info_text_monthly', {
              ReactivationDate: nextPaymentDate,
              SubscriptionPrice: `${Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency,
              }).format(price)}`,
            })
          "
        />
      </div>
      <!-- Cancelled Subscription -->
      <div v-else-if="isCancelledSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-orange-600': isCancelledSubscription }">
          {{ $t('subscription_status_canceled') }}
        </div>
        <p
          v-if="subscriptionTerm === 'annual'"
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_canceled_info_text_annual', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', { EndDate: endDate })
          "
        />
        <p
          v-else
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_canceled_info_text_monthly', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_monthly_pro', {
                  EndDate: endDate,
                })
          "
        />
      </div>
      <!-- Expired Subscription -->
      <div v-else-if="isExpiredSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-red-800': isExpiredSubscription }">
          {{ $t('subscription_status_expired') }}
        </div>
        <p
          v-if="subscriptionTerm === 'annual'"
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_expired_info_text_annual', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', {
                  EndDate: endDate,
                })
          "
        />
        <p
          v-else
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_expired_info_text_monthly', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', {
                  EndDate: endDate,
                })
          "
        />
      </div>
    </div>

    <DangerButton
      v-if="!productName.includes('Rate Connect') && !productName.includes('rateConnect') && isActiveSubscription"
      size="medium"
      class="tw-w-full sm:tw-w-auto tw-mt-2"
      @click="() => toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
    >
      {{ $t('subscriptions.details.cancel.cta') }}
    </DangerButton>
    <MainButton
      v-if="!isActiveSubscription && subscription?.type === 'PLUS' && !hasActiveRC"
      size="medium"
      class="tw-w-full md:tw-w-auto tw-mt-2"
      @click="openReactivationModal"
    >
      {{ $t('business_studio_plus_reactivate_cta') }}
    </MainButton>
  </div>
  <CancellationModal
    :isOpen="isCancellationOpen"
    :accommodationName="currentAccommodation.name"
    :accommodationId="accommodationId"
    :userDetails="userDetails"
    :partnerId="studioPlusCampaign?.partnerId"
    :language="language"
    :type="subscription?.type"
    @closeModal="toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
  />

  <StudioPlusReactivationModal
    v-if="isReactivationModalOpen || (reactivationCompleted && accommodationId === reactivationAccommodationId)"
    :isOpen="isReactivationModalOpen"
    :expired="isExpiredSubscription"
    :cancelled="isCancelledSubscription"
    :accommodationId="{ value: accommodationId }"
    :accommodationName="currentAccommodation.name"
    source="subscription-view"
    @closeModal="closeReactivationModal"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { useUserDetails } from '@/layouts/queries'

import MainButton from '@/components/base/buttons/MainButton.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import CancellationModal from '@/components/settings/subscriptions/cancellation/CancellationModal.vue'
import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'

import { useSubscriptions } from '@/components/settings/subscriptions/queries'

const store = useStore()

const props = defineProps({
  subscription: Array,
  accommodationId: Number,
  productName: String,
  isRateConnectActive: Boolean,
  currentAccommodation: Object,
})

const reactivationCompleted = ref(false)
const isReactivationModalOpen = ref(false)
const isCancellationOpen = ref(false)

const userId = computed(() => store.state.session.userId)
const language = computed(() => store.state.locale.language)
const currency = computed(() => store.state.currency)

const { userDetails } = useUserDetails(userId)
const { subscriptionsByAccommodations, isLoading } = useSubscriptions(props.accommodationId)

const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const subscriptionTerm = computed(() => (props.subscription?.term === 'MONTHLY' ? 'monthly' : 'annual'))
const price = computed(() => props.subscription?.nextPaymentAmount)

const hasActiveRC = computed(() => {
  const rateConnectProductId = import.meta.env.VITE_RATE_CONNECT_PRODUCT_ID

  const rcSubscriptions = subscriptionsByAccommodations.value.find(
    item => item.accommodation.accommodation_id === props.accommodationId
  )

  if (rcSubscriptions) {
    for (let i = 0; i < rcSubscriptions.subscriptions.length; i++) {
      const subscription = rcSubscriptions.subscriptions[i]
      if (subscription.product?.id === rateConnectProductId) {
        if (subscription.status === 'active') {
          return true
        }
      }
    }
    return false
  } else {
    return false
  }
})

const nextPaymentDate = computed(() => {
  return new Date(props.subscription?.nextPaymentDate).toLocaleDateString(language?.value, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const endDate = computed(() => {
  return new Date(props.subscription?.endDate).toLocaleDateString(language?.value, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const isActiveSubscription = computed(() => {
  if (props.productName.includes('Rate Connect')) {
    return props.isRateConnectActive
  } else {
    return props.subscription?.endDate === null
  }
})

const isExpiredSubscription = computed(() => {
  return props.subscription?.endDate ? new Date() > new Date(props.subscription?.endDate) : false
})

const isCancelledSubscription = computed(() => {
  return props.subscription?.value?.endDate !== null && !isExpiredSubscription?.value
})

const toggleCancelSubscription = action => {
  if (action === 'open') {
    isCancellationOpen.value = true
  }
  if (action === 'close') {
    isCancellationOpen.value = false
  }
}

const openReactivationModal = () => {
  isReactivationModalOpen.value = true
}

const closeReactivationModal = () => {
  reactivationCompleted.value = false
  isReactivationModalOpen.value = false
}
</script>
<style scoped>
p :deep(a) {
  color: theme('colors.blue.800');
  text-decoration: underline;
}
</style>
